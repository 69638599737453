<template>
    <div>
        <section class="g-bg-pos-center g-bg-size-cover" style="background-image: url(assets/img/fondo-eyp-wide.png);">
            <div class="row no-gutters">
                <div class="col-md-6 order-md-1 g-bg-eyp">
                    <div class="g-px-50 g-py-50">
                        <div class=" g-mb-100">
                            <h1 class="g-color-white g-font-weight-600 g-font-size-40 g-letter-spacing-0_5 mb-4 font-color-title2">Estatutos y Protocolos</h1>
                            <p class="lead g-color-white g-font-weight-400 font-color-subtitle2">Revisa el Reglamento con las normas bajo las cuales opera la Defensoría. Acá podrás encontrar, entre otras materias, los requisitos para presentar un reclamo y los plazos asociados al procedimiento.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="container g-px-0 g-pa-60">
            <div class="row">
                <div class="col-lg-6 g-mb-30">
                    <div class="media g-brd-around panel-eyp g-pa-30 g-mb-20">
                        <img class="d-flex g-width-70 g-height-70 g-mt-3 g-mr-15" src="assets/img/eyp.png" alt="eyp">
                        <div class="media-body">
                            <div class="g-mb-15">
                                <h5 class="d-flex justify-content-between align-items-center h6 g-color-orange mb-0 g-font-weight-700">
                                    <span class="d-block g-mr-10 font-color-name">Reglamento de Defensoría del Cliente</span>
                                </h5>
                            </div>
                            <p class="font-color-subtitlename">Haz <a href="assets/doc/reglamento_defensor.pdf" target="_blank">click acá</a> para descargar este documento.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 g-mb-30">
                    <div class="media g-brd-around panel-eyp g-pa-30 g-mb-20">
                        <img class="d-flex g-width-70 g-height-70 g-mt-3 g-mr-15" src="assets/img/eyp.png" alt="eyp">
                        <div class="media-body">
                            <div class="g-mb-15">
                                <h5 class="d-flex justify-content-between align-items-center h6 g-color-orange mb-0 g-font-weight-700">
                                    <span class="d-block g-mr-10 font-color-name">Formulario de Reclamo</span>
                                </h5>
                            </div>
                           <p class="font-color-subtitlename">Haz <a href="assets/doc/formulario_reclamo_defensor.pdf" target="_blank">click acá</a> 
                                para descargar el formulario. Puedes presentarlo en nuestra oficina o en la sucursal bancaria adscrita en la Defensoría.<br> Revisa las oficinas 
                               <router-link :to="{name: 'associates'}">aquí.</router-link>  </p>
                        </div>
                    </div>
                </div>

             
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        beforeMount () {
            window.scrollTo(0, 0);
        },
    }
</script>

<style lang="scss">
  .font-color-name {
      font-weight: 700;
    color:#ff9912 !important;
    font-size:1rem;
    line-height: 1.4;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .font-color-subtitlename {
    font-weight: 400;
    font-size: 0.9rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #555;
  }
    .font-color-subtitle2 {
    font-weight: 400;
    font-size: 17px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #fff !important;
  }
   .font-color-title2 {
    font-weight: 600;
     font-size: 2.85714rem !important;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #fff !important;
  }
  </style>